<template>
  <div>
    <v-container fluid class="px-6 py-6">
      <v-row>
        <v-col md="12">
          <v-card class="mb-6 card-shadow border-radius-xl py-4">
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="4" md="2">
                  <v-select
                    v-model="selectedReviewStatus"
                    :items="reviewOptions"
                    item-text="title"
                    item-value="value"
                    label="審核狀態"
                    :disabled="!isLoaded"
                    :menu-props="{ closeOnContentClick: true }"
                  >
                    <template v-slot:prepend-item>
                      <v-list-item ripple @click="selectedReviewStatus = null">
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ $t(`common['Nothing to select']`) }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="12" sm="4" md="2">
                  <v-select
                    v-model="selectedGrantStatus"
                    :items="grantOptions"
                    item-text="title"
                    item-value="value"
                    label="匯款狀態"
                    :disabled="!isLoaded"
                    :menu-props="{ closeOnContentClick: true }"
                  >
                    <template v-slot:prepend-item>
                      <v-list-item ripple @click="selectedGrantStatus = null">
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ $t(`common['Nothing to select']`) }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-row>
                    <v-col cols="6">
                      <v-select
                        :items="years"
                        v-model="selectedYear"
                        label="年份"
                        :disabled="!isLoaded"
                        :menu-props="{ closeOnContentClick: true }"
                      >
                        <template v-slot:prepend-item>
                          <v-list-item ripple @click="selectedYear = null">
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ $t(`common['Nothing to select']`) }}
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider class="mt-2"></v-divider>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col cols="6">
                      <v-select
                        :items="months"
                        v-model="selectedMonth"
                        item-text="name"
                        item-value="value"
                        label="月份"
                        :disabled="!isLoaded"
                        :menu-props="{ closeOnContentClick: true }"
                      >
                        <template v-slot:prepend-item>
                          <v-list-item ripple @click="selectedMonth = null">
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ $t(`common['Nothing to select']`) }}
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider class="mt-2"></v-divider>
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  class="d-flex align-center"
                  :class="{
                    'pt-5': $vuetify.breakpoint.md || $vuetify.breakpoint.lg,
                  }"
                >
                  <div>
                    <v-btn
                      class="
                        font-weight-normal
                        text-capitalize
                        btn-primary
                        bg-gradient-secondary
                        py-3
                        px-6
                        ms-3
                      "
                      @click="getDataFromApi"
                      :disabled="!isLoaded"
                      :loading="!isLoaded"
                    >
                      {{ $t('common["Search"]') }}</v-btn
                    >
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  class="d-flex align-center"
                  :class="{
                    'pt-5': $vuetify.breakpoint.md || $vuetify.breakpoint.lg,
                  }"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="
                          font-weight-normal
                          text-capitalize
                          btn-primary
                          bg-gradient-info
                          py-3
                          px-6
                          ms-3
                        "
                        v-on="on"
                        @click="exportWithdrawals"
                        :disabled="!isLoaded || isDownloading"
                        :loading="isDownloading"
                      >
                        匯出請款表單
                      </v-btn>
                    </template>
                    <span>表單內容為營運審核提領申請通過的特店名單</span>
                  </v-tooltip>

                  <v-btn
                    class="
                      font-weight-normal
                      text-capitalize
                      btn-primary
                      bg-gradient-warning
                      py-3
                      px-6
                      ms-3
                    "
                    @click="exportBankRemittance"
                    :disabled="!isLoaded || isDownloading"
                    :loading="isDownloading"
                  >
                    匯出請款指示銀行匯款單
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title>
              特店列表
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="fa-search"
                label="搜尋店名"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-card-text>
              <v-data-table
                ref="withdrawalTable"
                :headers="tableHeaders"
                :items="tableData"
                :loading="!isLoaded"
                :loading-text="$t(`common['Loading... Please wait']`)"
                :no-data-text="$t(`common['No Data']`)"
                :options.sync="pagination"
                :server-items-length="total"
                :footer-props="{
                  'items-per-page-text': $t(`common['Rows per page']`),
                }"
                :page.sync="pagination.page"
                hide-default-footer
                @page-count="pagination.pageCount = $event"
                :items-per-page="pagination.itemsPerPage"
              >
                <template v-slot:item.amount="{ item }">
                  {{ formatNumber(item.amount, 0) }}
                </template>
                <template v-slot:item.grantStatus="{ item }">
                  <span v-if="item.grantStatus === 'granted'"> 匯款成功 </span>
                  <span v-else-if="item.grantStatus === 'failed'">
                    匯款失敗
                  </span>
                  <span v-else-if="item.grantStatus === 'processing'">
                    處理中
                  </span>
                  <span v-else> 等待審核 </span>
                </template>
                <template v-slot:item.approvalStatus="{ item }">
                  <span v-if="item.approvalStatus === 'approved'"> 核准 </span>
                  <span v-else-if="item.approvalStatus === 'insufficient'">
                    待補件
                  </span>
                  <span v-else-if="item.approvalStatus === 'rejected'">
                    退回
                  </span>
                  <span v-else> 審核中 </span>
                  <v-tooltip bottom v-if="canAudit">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="mx-2"
                        fab
                        dark
                        x-small
                        color="teal"
                        v-on="on"
                        :disabled="
                          item.approvalStatus === 'approved' ||
                          item.approveStatus === 'rejected'
                        "
                        v-if="
                          item.approvalStatus !== 'approved' &&
                          item.approveStatus !== 'rejected'
                        "
                        @click="onApproveClick(item)"
                      >
                        <v-icon>fas fa-edit</v-icon>
                      </v-btn>
                    </template>
                    <span>審核</span>
                  </v-tooltip>
                </template>

                <template v-slot:item.createdAt="{ item }">
                  <span>{{
                    item.createdAt
                      ? moment
                          .unix(item.createdAt)
                          .format("YYYY/MM/DD HH:mm:ss")
                      : ""
                  }}</span>
                </template>
                <template v-slot:item.updatedAt="{ item }">
                  <span>{{
                    item.updatedAt
                      ? moment
                          .unix(item.updatedAt)
                          .format("YYYY/MM/DD HH:mm:ss")
                      : ""
                  }}</span>
                </template>
                <template v-slot:item.actions="props">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="mx-2"
                        dark
                        fab
                        x-small
                        color="blue darken-2"
                        v-on="on"
                        @click="onEditItem(props.item, 'view')"
                      >
                        <v-icon>fas fa-eye</v-icon>
                      </v-btn>
                    </template>
                    <span>查看詳細內容</span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="canEdit">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="mx-2"
                        fab
                        dark
                        x-small
                        :color="
                          props.item.approvalStatus === 'approved' ||
                          props.item.approvalStatus === 'rejected'
                            ? '#DBDCDC'
                            : 'cyan'
                        "
                        :style="{
                          cursor:
                            props.item.approvalStatus === 'approved' ||
                            props.item.approvalStatus === 'rejected'
                              ? 'not-allowed'
                              : 'pointer',
                        }"
                        v-on="on"
                        @click="
                          props.item.approvalStatus === 'approved' ||
                          props.item.approvalStatus === 'rejected'
                            ? ''
                            : onEditItem(props.item, 'edit')
                        "
                      >
                        <v-icon>fas fa-edit</v-icon>
                      </v-btn>
                    </template>
                    <span>編輯/提領申請補件</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-card-text>
            <v-card-actions class="card-padding">
              <Pagination
                :pagination="pagination"
                :total="total"
                :loading="!isLoaded"
                @update="getDataFromApi;"
              ></Pagination>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

      <v-dialog v-model="openApproveDialog" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">特店提領申請審核</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="approveForm">
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-radio-group
                      v-model="reviewStatus"
                      row
                      mandatory
                      class="large-radio"
                    >
                      <v-radio
                        label="核准"
                        :value="'approved'"
                        :color="reviewStatus === 'approved' ? '#1867c0' : ''"
                      ></v-radio>
                      <v-radio
                        label="補件"
                        :value="'insufficient'"
                        :color="
                          reviewStatus === 'insufficient' ? '#1867c0' : ''
                        "
                      ></v-radio>
                      <!-- <v-radio
                        label="退回"
                        :value="'rejected'"
                        :color="reviewStatus === 'rejected' ? '#1867c0' : ''"
                      ></v-radio> -->
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      v-model="comment"
                      outlined
                      label="簽核意見"
                      :rules="rules.required"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <!-- <v-btn color="blue darken-1" text @click="uploadImage">Upload</v-btn> -->
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="openApproveDialog = false"
            >
              {{ $t(`common['Cancel']`) }}
            </v-btn>
            <v-btn color="blue darken-1" text @click="onApprove">{{
              $t(`common['Save']`)
            }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="openEditDialog" persistent max-width="800px">
        <v-card>
          <v-card-title>
            <span class="text-h5"> 提領申請審核 </span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="editForm" lazy-validation>
                <v-row class="mx-auto">
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="editItem.storeName"
                      label="特店名稱"
                      :rules="rules.name"
                      :disabled="true"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="editItem.amount"
                      label="提領點數"
                      :disabled="true"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" v-if="viewOnly">
                    <v-text-field
                      v-model="editItem.createdAtStr"
                      label="申請日期"
                      :disabled="viewOnly"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="mx-auto">
                  <v-col cols="12" v-if="!viewOnly">
                    <v-row>
                      <v-col cols="6">
                        <v-file-input
                          accept="image/*"
                          label="發票圖檔"
                          v-model="uploadInvoiceImg"
                          @change="onImageFileChange('invoice')"
                          :disabled="isProcessing"
                        ></v-file-input>
                      </v-col>
                      <v-col cols="6">
                        <v-file-input
                          accept="image/*"
                          label="統編圖檔"
                          v-model="uploadBanImg"
                          @change="onImageFileChange('ban')"
                          :disabled="isProcessing"
                        ></v-file-input>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12">
                    <v-row>
                      <v-col cols="6">
                        <div class="subheading">
                          發票/收據證明
                          <a
                            v-if="viewOnly && editItem.invoiceImageUrl"
                            :href="editItem.invoiceImageUrl"
                            target="_blank"
                          >
                            （查看全圖）
                          </a>
                        </div>
                        <v-img
                          v-if="uploadInvoiceImg"
                          :contain="true"
                          :width="300"
                          :max-height="300"
                          :src="uploadInvoiceImgUrl"
                        >
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                                color="black"
                                :size="30"
                              ></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                        <v-img
                          v-else
                          :contain="true"
                          :width="300"
                          :max-height="300"
                          :src="editItem.invoiceImageUrl"
                        >
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                                color="black"
                                :size="30"
                              ></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-col>
                      <v-col cols="6">
                        <div class="subheading">
                          統一編號章
                          <a
                            v-if="viewOnly && editItem.banImageUrl"
                            :href="editItem.banImageUrl"
                            target="_blank"
                          >
                            （查看全圖）
                          </a>
                        </div>
                        <v-img
                          v-if="uploadBanImg"
                          :contain="true"
                          :width="300"
                          :max-height="300"
                          :src="uploadBanImgUrl"
                        >
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                                color="black"
                                :size="30"
                              ></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                        <v-img
                          v-else
                          :contain="true"
                          :width="300"
                          :max-height="300"
                          :src="editItem.banImageUrl"
                        >
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                                color="black"
                                :size="30"
                              ></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <!-- <v-row>
                  <v-col cols="12" sm="6">
                    <div class="subheading">發票</div>
                    <v-img
                      :src="editItem.invoiceImageUrl"
                      aspect-ratio="1.7"
                    ></v-img>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <div class="subheading">統一編號章</div>
                    <v-img
                      :src="editItem.banImageUrl"
                      aspect-ratio="1.7"
                    ></v-img>
                  </v-col>
                </v-row> -->
              </v-form>
            </v-container>
            <!-- <small>*indicates required field</small> -->
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeEditDialog">
              {{ $t(`common['Close']`) }}
            </v-btn>
            <v-btn
              v-if="!viewOnly"
              color="blue darken-1"
              text
              @click="onSaveEditItem"
            >
              {{ $t(`common['Save']`) }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";

import {
  fetchWithdrawals,
  exportApprovedWithdrawalsList,
  exportBankRemittance,
} from "src/apis/fetchData";
import { approveWithdrawal } from "src/apis/updateData";
import { updateWithdraw } from "src/apis/pointsCore";

import { formatNumber, checkPermission } from "src/util/utils";
import { generateArrayOfYears, generateArrayOfMonths } from "src/util/utils";
import Pagination from "../Campaigns/Widgets/Pagination.vue";

export default {
  name: "WithdrawalList",
  components: { Pagination },
  data() {
    return {
      moment: moment,
      formatNumber: formatNumber,
      pagination: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,
      },
      reviewOptions: [
        {
          value: "pending",
          title: "審核中",
        },
        {
          value: "approved",
          title: "核准",
        },
        {
          value: "insufficient",
          title: "待補件",
        },
        {
          value: "rejected",
          title: "退回",
        },
      ],
      selectedReviewStatus: null,
      grantOptions: [
        {
          value: "processing",
          title: "處理中",
        },
        {
          value: "granted",
          title: "匯款完成",
        },
        {
          value: "failed",
          title: "匯款失敗",
        },
      ],
      selectedGrantStatus: null,
      isLoaded: true,
      isNew: false,
      viewOnly: false,
      openApproveDialog: false,
      openEditDialog: false,
      singleExpand: true,
      expanded: [],
      tableData: [],
      tableHeaders: [
        {
          text: "提領ID",
          sortable: true,
          value: "id",
        },
        {
          text: "特店名稱",
          sortable: true,
          value: "storeName",
        },
        {
          text: "特店ID",
          sortable: true,
          value: "storeId",
        },
        {
          text: "聯絡人",
          sortable: true,
          value: "contactName",
        },
        {
          text: "聯絡人電子郵件",
          sortable: true,
          value: "contactEmail",
        },
        {
          text: "聯絡人電話",
          sortable: true,
          value: "contactPhone",
        },
        {
          text: "提領點數",
          sortable: true,
          align: "right",
          value: "amount",
        },
        {
          text: "審核狀態",
          sortable: true,
          filterable: false,
          value: "approvalStatus",
        },
        {
          text: "匯款狀態",
          sortable: true,
          filterable: false,
          value: "grantStatus",
        },
        {
          text: "建立時間",
          sortable: true,
          filterable: false,
          value: "createdAt",
        },
        {
          text: "更新時間",
          sortable: true,
          filterable: false,
          value: "updatedAt",
        },
        { text: "", value: "actions", sortable: false },
      ],
      search: null,
      reviewStatus: null,
      comment: null,
      approveItem: null,
      rules: {
        required: [(v) => !!v || this.$t(`common["Required"]`)],
      },
      defaultEditItem: {
        name: null,
      },
      editItem: {
        name: null,
      },
      years: [],
      months: [],
      selectedYear: null,
      selectedMonth: null,
      isDownloading: false,
      total: 0,
      debounce: null,
      uploadBanImg: null,
      uploadInvoiceImg: null,
      uploadBanImgUrl: null,
      uploadInvoiceImgUrl: null,
      isProcessing: false,
    };
  },
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.isLoggedIn,
      ssoUser: (state) => state.ssoUser,
      pointSettings: (state) => state.pointSettings,
      permissions: (state) => state.userPermissions,
    }),
    merchantId() {
      return this.isLoggedIn ? this.ssoUser.user._json.groups.merchant : null;
    },
    organizationId() {
      return this.isLoggedIn
        ? this.ssoUser.user._json.groups.organization
        : null;
    },
    canEdit() {
      if (this.permissions && this.permissions.length) {
        return checkPermission(
          this.permissions,
          "Admin:AccountingManage",
          "EditWithdrawals",
          true
        );
      }
      return false;
    },
    canAudit() {
      if (this.permissions && this.permissions.length) {
        return checkPermission(
          this.permissions,
          "Admin:AccountingManage",
          "AuditWithdrawals",
          true
        );
      }
      return false;
    },
  },
  watch: {
    pagination: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
    search: {
      handler() {
        clearTimeout(this.debounce);
        let self = this;
        this.debounce = setTimeout(function () {
          self.getDataFromApi();
        }, 200);
      },
    },
  },
  created() {
    this.unwatchIsLoggedIn = this.$watch("isLoggedIn", (newVal) => {
      if (newVal) {
        this.unwatchIsLoggedIn();
        // this.getWithdrawals();
        this.getDataFromApi();
      }
    });
  },
  mounted() {
    if (this.isLoggedIn) {
      //this.getWithdrawals();
      this.getDataFromApi();
    }
    this.years = generateArrayOfYears();
    this.months = generateArrayOfMonths();
  },
  methods: {
    showExpanded(item) {
      this.expanded = item === this.expanded[0] ? [] : [item];
    },
    // getWithdrawals() {
    //   if (this.selectedMonth && !this.selectedYear) {
    //     this.$swal({
    //       text: "請選擇年份",
    //       type: "error",
    //       showCancelButton: false,
    //       confirmButtonText: this.$i18n.t(`common["Confirm"]`),
    //       customClass: {
    //         confirmButton: "btn bg-gradient-success",
    //         cancelButton: "btn bg-gradient-danger",
    //       },
    //     });
    //     return;
    //   }
    //   this.isLoaded = false;
    //   let startTs = null;
    //   let endTs = null;
    //   if (this.selectedYear && this.selectedMonth) {
    //     startTs = moment(
    //       `${this.selectedYear}-${this.selectedMonth}-01`
    //     ).unix();
    //     endTs = moment(`${this.selectedYear}-${this.selectedMonth}-01`)
    //       .add(1, "months")
    //       .unix();
    //   } else if (this.selectedYear && !this.selectedMonth) {
    //     startTs = moment(`${this.selectedYear}-01-01`).unix();
    //     endTs = moment(`${this.selectedYear}-01-01`).add(1, "years").unix();
    //   }
    //   let query = {
    //     approveStatus: this.selectedReviewStatus,
    //     grantStatus: this.selectedGrantStatus,
    //     startTs: startTs,
    //     endTs: endTs,
    //   };
    //   fetchWithdrawals(this.merchantId, query)
    //     .then((res) => {
    //       console.log("fetchWithdrawals done", res);
    //       this.tableData = [...res.data];
    //     })
    //     .catch((e) => {
    //       console.log("fetchWithdrawals failed", e);
    //     })
    //     .finally(() => {
    //       this.isLoaded = true;
    //     });
    // },
    getDataFromApi() {
      if (this.selectedMonth && !this.selectedYear) {
        this.$swal({
          text: "請選擇年份",
          type: "error",
          showCancelButton: false,
          confirmButtonText: this.$i18n.t(`common["Confirm"]`),
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
        });
        return;
      }
      this.isLoaded = false;
      this.getWithdrawals()
        .then((data) => {
          this.tableData = [...data.items];
          this.total = data.total;
          this.isLoaded = true;
          // this.$vuetify.goTo(this.$refs.withdrawalTable);
        })
        .finally(() => {
          this.isLoaded = true;
        });
    },
    getWithdrawals() {
      this.tableData = [];
      return new Promise((resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.pagination;
        // console.log("pagination", this.pagination);
        let startTs = null;
        let endTs = null;
        if (this.selectedYear && this.selectedMonth) {
          startTs = moment(
            `${this.selectedYear}-${this.selectedMonth}-01`
          ).unix();
          endTs = moment(`${this.selectedYear}-${this.selectedMonth}-01`)
            .add(1, "months")
            .unix();
        } else if (this.selectedYear && !this.selectedMonth) {
          startTs = moment(`${this.selectedYear}-01-01`).unix();
          endTs = moment(`${this.selectedYear}-01-01`).add(1, "years").unix();
        }
        let query = {
          approveStatus: this.selectedReviewStatus,
          grantStatus: this.selectedGrantStatus,
          startTs: startTs,
          endTs: endTs,
          offset: (page - 1) * itemsPerPage || 0,
          limit: itemsPerPage,
          sortBy: sortBy && sortBy.length ? sortBy[0] : null,
          desc: sortDesc,
          search: this.search,
        };
        let items = [];
        let total = 0;
        // sortby/search 參數是否要丟後端處理
        fetchWithdrawals(this.merchantId, query)
          .then((res) => {
            // console.log("fetchWithdrawals done:" + JSON.stringify(res));
            items = [...res.data.withdrawals];
            total = res.data.total;
          })
          .catch((e) => {
            console.log("fetchWithdrawals failed", e);
            this.$swal({
              text: "無法取得提領申請資料",
              type: "error",
              showCancelButton: false,
              confirmButtonText: this.$i18n.t(`common["Confirm"]`),
              customClass: {
                confirmButton: "btn bg-gradient-success",
                cancelButton: "btn bg-gradient-danger",
              },
            });
          })
          .finally(() => {
            setTimeout(() => {
              resolve({
                items,
                total,
              });
            }, 1000);
          });
      });
    },
    onApproveClick(item) {
      console.log("on approve click:" + JSON.stringify(item));
      this.approveItem = item;
      this.openApproveDialog = true;
    },
    onApprove() {
      console.log("approve budget:" + JSON.stringify(this.approveItem));
      if (this.$refs.approveForm) {
        const valid = this.$refs.approveForm.validate();
        if (valid) {
          const data = {
            approver: this.ssoUser.userId,
            approveStatus: this.reviewStatus,
            comment: this.comment,
          };
          approveWithdrawal(this.merchantId, this.approveItem.id, data)
            .then((res) => {
              console.log("approveWithdrawal done", res);
              // this.approveItem.approvalStatus = data.approvalStatus;
              // this.approveItem.comment = data.comment;
              setTimeout(this.getDataFromApi(), 0);
              this.comment = null;
              this.reviewStatus = null;
              this.openApproveDialog = false;
            })
            .catch((e) => {
              console.log("approveWithdrawal failed", e.response);
              let errmsg = "無法更新提領申請審核結果";
              if (e.response && e.response.data && e.response.data.message) {
                errmsg += "<br/>" + e.response.data.message;
              }
              this.isNew = false;
              this.showErrorAlert(errmsg);
            });
        } else {
          this.openApproveDialog(this.$i18n.t(`errors["Incorrect Fields"]`));
        }
      }
    },
    onEditItem(item, type) {
      if (item) {
        this.editItem = Object.assign({}, item);
        this.isNew = false;
      } else {
        this.editItem = Object.assign({}, this.defaultEditItem);
        this.isNew = true;
      }
      if (type === "view") {
        this.viewOnly = true;
        this.editItem.createdAtStr = moment
          .unix(this.editItem.createdAt)
          .format("YYYY/MM/DD HH:mm:ss");
      } else {
        this.viewOnly = false;
      }
      this.openEditDialog = true;
    },
    closeEditDialog() {
      this.$refs.editForm.resetValidation();
      this.$refs.editForm.reset();
      this.uploadBanImg = null;
      this.uploadBanImgUrl = null;
      this.uploadInvoiceImg = null;
      this.uploadInvoiceImgUrl = null;
      this.openEditDialog = false;
    },
    onSaveEditItem() {
      const valid = this.$refs.editForm.validate();
      if (valid) {
        console.log("save edit item:" + JSON.stringify(this.editItem));
        let formData = new FormData();
        if (this.uploadBanImg) {
          formData.append("banImage", this.uploadBanImg);
        }
        if (this.uploadInvoiceImg) {
          formData.append("invoiceImage", this.uploadInvoiceImg);
        }
        formData.append("memberId", this.ssoUser.userId);
        // console.log("formData:", formData);
        this.isProcessing = true;
        updateWithdraw(
          this.merchantId,
          this.editItem.entityId,
          this.editItem.id,
          formData
        )
          .then((res) => {
            console.log("updateWithdraw done", res);
            setTimeout(this.getDataFromApi(), 0);
            this.closeEditDialog();
          })
          .catch((e) => {
            console.log("updateWithdraw failed", e.response);
            let errmsg = "無法更新提領申請";
            if (e.response && e.response.data && e.response.data.message) {
              errmsg += "<br/>" + e.response.data.message;
            }
            this.showErrorAlert(errmsg);
          })
          .finally(() => {
            this.isProcessing = false;
          });
      } else {
        this.showErrorAlert(this.$i18n.t(`errors["Incorrect Fields"]`));
      }
    },
    exportWithdrawals() {
      this.isDownloading = true;
      let startTs = null;
      let endTs = null;
      if (this.selectedYear && this.selectedMonth) {
        startTs = moment(
          `${this.selectedYear}-${this.selectedMonth}-01`
        ).unix();
        endTs = moment(`${this.selectedYear}-${this.selectedMonth}-01`)
          .add(1, "months")
          .unix();
      } else if (this.selectedYear && !this.selectedMonth) {
        startTs = moment(`${this.selectedYear}-01-01`).unix();
        endTs = moment(`${this.selectedYear}-01-01`).add(1, "years").unix();
      }
      let query = {
        approveStatus: this.selectedReviewStatus,
        grantStatus: this.selectedGrantStatus,
        startTs: startTs,
        endTs: endTs,
      };
      function s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
      }
      exportApprovedWithdrawalsList(this.merchantId, query)
        .then((res) => {
          console.log("exportApprovedWithdrawalsList done", res);
          if (res && res.data) {
            const blob = s2ab(res.data);
            var fileURL = window.URL.createObjectURL(new Blob([blob]), {
              type: "application/octer-stream",
            });
            var fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute(
              "download",
              `export-withdrawals-${new Date().getTime()}.xlsx`
            );
            document.body.appendChild(fileLink);

            fileLink.click();
          } else {
            throw new Error("No Data");
          }
        })
        .catch((e) => {
          console.log("exportApprovedWithdrawalsList failed", e);
          let errmsg = "無法匯出請款表單，請稍後重試";
          if (e.response && e.response.data && e.response.data.message) {
            errmsg += "<br/>" + e.response.data.message;
          }
          this.showErrorAlert(errmsg, "匯出失敗");
        })
        .finally(() => {
          this.isDownloading = false;
        });
    },
    exportBankRemittance() {
      this.isDownloading = true;
      let startTs = null;
      let endTs = null;
      if (this.selectedYear && this.selectedMonth) {
        startTs = moment(
          `${this.selectedYear}-${this.selectedMonth}-01`
        ).unix();
        endTs = moment(`${this.selectedYear}-${this.selectedMonth}-01`)
          .add(1, "months")
          .unix();
      } else if (this.selectedYear && !this.selectedMonth) {
        startTs = moment(`${this.selectedYear}-01-01`).unix();
        endTs = moment(`${this.selectedYear}-01-01`).add(1, "years").unix();
      }
      let query = {
        approveStatus: this.selectedReviewStatus,
        grantStatus: this.selectedGrantStatus,
        startTs: startTs,
        endTs: endTs,
      };
      function s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
      }
      exportBankRemittance(this.merchantId, query)
        .then((res) => {
          console.log("exportBankRemittance done", res);
          if (res && res.data) {
            const blob = s2ab(res.data);
            var fileURL = window.URL.createObjectURL(new Blob([blob]), {
              type: "application/octer-stream",
            });
            var fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute(
              "download",
              `請款指示銀行匯款單-${new Date().getTime()}.xlsx`
            );
            document.body.appendChild(fileLink);

            fileLink.click();
          } else {
            throw new Error("No Data");
          }
        })
        .catch((e) => {
          console.log("exportBankRemittance failed", e);
          let errmsg = "無法匯出請款指示銀行匯款單，請稍後重試";
          if (e.response && e.response.data && e.response.data.message) {
            errmsg += "<br/>" + e.response.data.message;
          }
          this.showErrorAlert(errmsg, "匯出失敗");
        })
        .finally(() => {
          this.isDownloading = false;
        });
    },
    showErrorAlert(message, inputTitle) {
      let title = this.$i18n.t(`errors["Update"]`);
      if (this.isNew) {
        title = this.$i18n.t(`errors["Create"]`);
      }
      if (inputTitle) {
        title = inputTitle;
      }
      this.$swal({
        title: title,
        html: message,
        type: "error",
        showCancelButton: false,
        confirmButtonText: this.$i18n.t(`common["Confirm"]`),
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
      });
    },
    onImageFileChange(type) {
      if (type === "invoice") {
        this.uploadInvoiceImgUrl = null;
        if (this.uploadInvoiceImg) {
          this.uploadInvoiceImgUrl = URL.createObjectURL(this.uploadInvoiceImg);
        }
      } else if (type === "ban") {
        this.uploadBanImgUrl = null;
        if (this.uploadBanImg) {
          this.uploadBanImgUrl = URL.createObjectURL(this.uploadBanImg);
        }
      }
    },
  },
};
</script>
<style scoped>
.order-1 {
  order: 1;
}
.order-2 {
  order: 2;
}
.order-3 {
  order: 3;
}
/* .radio-large::v-deep .v-icon {
  font-size: "24px !important";
} */
.large-radio::v-deep i {
  font-size: 24px;
}
.large-radio::v-deep label {
  font-size: 16px;
  padding-left: 3px;
}
.large-radio::v-deep .v-radio {
  padding: 0px;
}
/* .large-radio::v-deep [class*="__ripple"] {
  left: 0;
} */
</style>
